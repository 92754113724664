import { render, staticRenderFns } from "./GuideForDownloadApp.vue?vue&type=template&id=1214936a&scoped=true&"
import script from "./GuideForDownloadApp.vue?vue&type=script&lang=js&"
export * from "./GuideForDownloadApp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1214936a",
  null
  
)

export default component.exports